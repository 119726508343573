<template>
  <div class="page-bar">
    <ul>
      <li><a href="#" class="index" @click="changeOne()">首页</a></li>
      <li v-if="cur > 1">
        <a v-on:click="cur--, pageClick()" class="index">上一页</a>
      </li>
      <li v-if="cur == 1"><a class="banclick">上一页</a></li>
      <li
        v-for="index in indexs"
        v-bind:class="{ active: cur == index }"
        :key="index.id"
      >
        <a v-on:click="btnClick(index)" class="pageinfo">{{ index }}</a>
      </li>
      <li v-if="cur != all">
        <a v-on:click="cur++, pageClick()" class="index">下一页</a>
      </li>
      <li v-if="cur == all"><a class="banclick">下一页</a></li>
      <li>
        <a class="index-all"
          >共<i>{{ all }}</i
          >页</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 5,
    },
    total: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      all: 1, //总页数
      cur: 1, //当前页码
      totalPage: 10, //当前条数
    };
  },
  watch: {
    total(newVlaue) {
      this.all = Math.ceil(newVlaue / this.pageSize);
    },
    page(newVlaue) {
      this.cur = newVlaue;
    },
    pageSize(newValue) {
      this.totalPage = newValue;
    },
  },
  created() {
    this.all = Math.ceil(this.total / 10);
    this.cur = this.page;
    this.totalPage = 10;
  },
  methods: {
    //分页
    btnClick: function (data) {
      //页码点击事件
      if (data != this.cur) {
        this.cur = data;
      }
      this.$emit("change", this.cur);
    },
    pageClick: function () {
      this.$emit("change", this.cur);
    },
    changeOne(){
      this.cur = 1;
       this.$emit("change", this.cur);
    }
  },
  computed: {
    //分页
    indexs: function () {
      var left = 1;
      var right = this.all;
      var ar = [];
      if (this.all >= 5) {
        if (this.cur > 3 && this.cur < this.all - 2) {
          left = this.cur - 2;
          right = this.cur + 2;
        } else {
          if (this.cur <= 3) {
            left = 1;
            right = 5;
          } else {
            right = this.all;
            left = this.all - 4;
          }
        }
      }
      while (left <= right) {
        ar.push(left);
        left++;
      }
      return ar;
    },
  },
};
</script>

<style lang="less" scoped>
.pageinfo {
  width: 34px;
  height: 34px;
  background: #ffffff;
}
.index-all {
  width: 70px;
  height: 34px;
  font-size: 14px;
  font-weight: 700;
  border-right: 1px solid #dddddd;
}
.index {
  width: 70px;
  height: 34px;
  font-size: 14px;
  font-weight: 700;
}
.page-bar {
  height: 34px;
  margin-top: 31px;
}
ul,
li {
  margin: 0px;
  padding: 0px;
}
li {
  list-style: none;

  text-align: center;
}
.page-bar li:first-child > a {
  margin-left: 0px;
}
.page-bar a {
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  text-decoration: none;
  position: relative;
  float: left;
  line-height: 1.42857143;
  color: #059e96;
  cursor: pointer;
  line-height: 34px;
}
.page-bar a:hover {
  background-color: #eee;
}
.page-bar a.banclick {
  cursor: not-allowed;
  width: 70px;
  height: 34px;
  font-size: 14px;
  font-weight: 700;
}
.page-bar .active a {
  color: #fff;
  cursor: default;
  background-color: #059e96;
  border-color: #059e96;
}
.page-bar i {
  font-style: normal;
  color: #059e96;
  margin: 0px 4px;
  font-size: 12px;
}
</style>