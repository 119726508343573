<template>
  <div class="head">
    <div class="logo">
      <router-link to='/'>
      <img src="../assets/images/logo.png" alt="" />
      </router-link>
    </div>
    <div class="search-right">
      <div class="search">
        <input
          type="text"
          v-model="key"
          class="input"
          placeholder="输入关键字搜索"
        />
        <div class="serch-but" @click="search()">
          <img src="../assets/images/search.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      key: "",
    };
  },

  watch: {},
  methods: {
    search() {
      var url = "/search?key=" + this.key;
      let route = this.$router.resolve({ path: url });
      window.open(route.href, "_blank");
    },
  },
};
</script>

<style lang="less" scope>
.head {
  width: 1200px;
  display: flex;
  margin: 0 auto;
  height: 300px;
  .logo {
    width: 525px;
    height: 128px;
    margin-top: 78px;
    margin-left: 16px;
  }
  .search-right {
    width: 380px;
    height: 128px;
    margin-top: 118px;
    margin-left: 240px;
    .search {
      width: 380px;
      height: 48px;
      display: flex;
      .input {
        width: 290px;
        height: 48px;
        display: inline-block;
        border: none;
        padding-left: 20px;
      }
      .input::-webkit-input-placeholder {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 19px;
        margin-left: 20px;
      }
      .serch-but {
        width: 90px;
        height: 48px;
        display: inline-block;
        cursor: pointer;
      }
    }
  }
}
</style>
