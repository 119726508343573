<template>
  <div class="container">
    <div class="header">
      <Header></Header>
    </div>
    <div class="nav">
      <Nav :navData="navList" :isActive="nav_id"></Nav>
    </div>
    <div class="content">
      <div class="wCenter">
        <!-- 导航栏 -->
        <div class="navigation">
          <span class="index">首页 > </span>
          <span>{{ title }}</span>
        </div>
        <!-- 左侧 -->
        <div class="news-notice">
          <div class="news">
            <div v-if="nav.length !== 0" class="news-head">
              <div
                :class="[nav_item.id == news_id ? 'type-active' : 'type']"
                v-for="(nav_item, nav_index) in nav"
                :key="nav_index"
                @click="jump(nav_item.id, nav_item.parent_id)"
              >
                {{ nav_item.name }}
              </div>
            </div>
            <div
              v-for="(news_item, news_index) in list"
              :key="news_index"
              class="news-list"
              @click="jumpInfo(news_item.id, news_item.category_id, nav_id)"
            >
              <div class="title">{{ news_item.post_title }}</div>
              <div class="date">{{ news_item.published_timesy }}</div>
            </div>
          </div>
          <!-- 右侧 -->
          <div>
            <div v-if="nav_id == 19" class="mechanism">
              <div class="m-item" @click="jump(13,12)">入会流程</div>
              <div class="m-item" @click="jump(14,12)">入会申请</div>
              <div class="m-item" @click="jump(15,12)">会费标准</div>
            </div>
            <div class="notices">
              <div class="news-img">
                <img src="../assets/images/hot.png" alt="" />
                <div class="news-more" @click="jump(hot[0]['parent_id'])">
                  更多>>
                </div>
                <div class="notices-title">热门资讯</div>
              </div>
              <div class="title-list">
                <div
                  v-for="(hot_item, hot_index) in hot"
                  :key="hot_index"
                  class="title-item"
                  @click="jumpInfo(hot_item.id, 9, 8)"
                >
                  <div class="title">{{ hot_item.post_title }}</div>
                  <div class="date">
                    [{{ hot_item.create_time | formatDate }}]
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="page.total > 0" class="page">
          <Page
            :page="this.page.page"
            :pageSize="this.page.pageSize"
            :total="this.page.total"
            @change="changePage"
          />
        </div>
      </div>
    </div>

    <!-- 底部 -->
    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import { getMechanism, getNav } from "@/http/api/home/index";
import Header from "../components/header.vue";
import Nav from "../components/nav.vue";
import Footer from "../components/footer.vue";
import Page from "../components/page.vue";
export default {
  data() {
    return {
      news_id: "",
      nav_id: "",
      list: [], // 数据列表
      nav: [], //导航
      title: "",
      href: "",
      navList: "",
      hot: [], //热门分类
      page: {
        page: 1,
        pageSize: 10,
        total: 10,
      },
    };
  },
  components: {
    Header,
    Nav,
    Footer,
    Page,
  },
  watch: {
    $route: {
      handler: function (val) {
        this.news_id = val.query.news_id;
        this.nav_id = val.query.nav_id;
        this.getMechanism();
      },
    },
  },
  created() {
    this.getNav();
    this.news_id = this.$route.query.news_id;
    this.nav_id = this.$route.query.nav_id;
    this.getMechanism();
    // this.census(); //菜单统计数据接口
  },
  filters: {
    formatDate: function (value) {
      let date = new Date(value * 1000);
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return MM + "-" + d;
    },
  },
  methods: {
    getMechanism() {
      getMechanism({ news_id: this.news_id, page: this.page.page }).then(
        (res) => {
          this.title = res.data.data.title;
          this.list = res.data.data.data.data;
          this.nav = res.data.data.nav;
          this.hot = res.data.data.hot;
          this.page.page = res.data.data.data.current_page;
          this.page.total = res.data.data.data.total;
        }
      );
    },
    jump(id, nav_id) {
      var url = "/news?news_id=" + id + "&nav_id=" + nav_id;
      this.$router.push({ path: url });
    },
    getNav() {
      getNav().then((res) => {
        this.navList = res.data.data;
      });
    },
    //跳转详情
    jumpInfo(id, news_id, nav_id) {
      var url = "/info?id=" + id + "&news_id=" + news_id + "&nav_id=" + nav_id;
      this.$router.push({ path: url });
    },
    // census() {
    //   census({ nav_id: this.nav_id }).then((res) => {
    //     console.log(res);
    //   });
    // },
    changePage(page) {
      //修改分页
      this.page.page = page;
      this.getMechanism();
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background: #f7f7f7;
  padding-top: 20px;
  .navigation {
    height: 44px;
    background: #ffffff;
    font-size: 16px;
    color: #666666;
    line-height: 44px;
    padding-left: 11px;
    .index {
      cursor: pointer;
    }
  }
  .news-notice {
    width: 100%;
    display: flex;
    padding-top: 20px;
    .news {
      width: 800px;
      background: #ffffff;
      padding: 0 20px 20px 20px;
      .news-head {
        border-bottom: 1px solid #dddddd;
        display: flex;
        height: 56px;
        color: #333333;
        .type {
          margin-right: 28px;
          width: auto;
          text-align: center;
          line-height: 56px;
          font-size: 16px;
          color: #333333;
          cursor: pointer;
        }
        .type-active {
          margin-right: 28px;
          width: auto;
          text-align: center;
          line-height: 56px;
          font-size: 16px;
          font-weight: 700;
          color: #059e96;
          border-bottom: 2px solid #059e96;
          cursor: pointer;
        }
      }
      .news-list {
        display: flex;
        height: 69px;
        line-height: 69px;
        border-bottom: 1px solid #dddddd;
        cursor: pointer;
        .title {
          width: calc(100% - 101px);
          font-size: 18px;
          color: #333333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .title:hover {
          color: #059e96;
        }
        .date {
          width: 101px;
          font-size: 18px;
          color: #666666;
        }
      }
    }
    .mechanism {
      width: 380px;
      height: 62px;
      background: #ffffff;
      margin-left: 20px;
      margin-bottom: 20px;
      display: flex;
      .m-item{
        width: 106px;
        height: 32px;
        background: #e4fcfc;
        font-size: 14px;
        font-weight: 700;
        color: #0390a0;
        margin:15px 0px 0px 15px;
        text-align: center;
        line-height: 32px;
        cursor: pointer;
      }
    }
    .notices {
      width: 380px;
      height: 429px;
      margin-left: 20px;
      background: #ffffff;
      padding: 20px;
      overflow: hidden;
      .news-img {
        width: 100%;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .news-more {
          position: absolute;
          right: 0px;
          top: 10px;
          font-size: 12px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
          font-weight: 400;
          text-align: left;
          color: #005a69;
          cursor: pointer;
        }
        .news-more:hover {
          color: #059e96;
          cursor: pointer;
        }
        .notices-title {
          font-size: 16px;
          font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
          font-weight: 700;
          color: #ffffff;
          position: absolute;
          top: 5px;
          left: 18px;
        }
      }
      .title-list {
        .title-item {
          cursor: pointer;
          margin-top: 21px;
          width: 100%;
          height: 19px;
          display: flex;
          .title {
            width: 280px;
            font-size: 14px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            color: #333333;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .title:hover {
            color: #059e96;
          }
          .date {
            margin-left: 10px;
            font-size: 14px;
            font-family: MicrosoftYaHei, MicrosoftYaHei-Regular;
            color: #999999;
            line-height: 19px;
          }
        }
      }
    }
  }
  .page {
    width: 454px;
    height: 64px;
    margin-left: 350px;
  }
}
</style>
