<template>
  <div class="nav">
    <div class="nav-left">
      <img src="../assets/images/nav-left.png" alt="" />
    </div>
    <div class="nav-center">
      <div class="nav-list">
        <div
          :class="[isActive == 0 ? 'item active' : 'item']"
          @click="jumpIndex()"
        >
          <div>首页</div>
          <img src="../assets/images/nav-drop.png" alt="" class="nav-drop" />
        </div>
        <div
          v-for="(item, index) in navData"
          :key="index"
          :class="[item.id == isActive ? 'item active' : 'item']"
          @click="jump(item.id, item.url, item.child, 1, 0)"
        >
          <div>{{ item.name }}</div>
          <img src="../assets/images/nav-drop.png" alt="" class="nav-drop" />
          <div class="box">
            <div
              v-for="(list, indexList) in item.child"
              :key="indexList"
              class="submenu"
              @click.stop="jump(list.id, list.url, '', 2, list.parent_id)"
            >
              {{ list.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nav-right">
      <img src="../assets/images/nav-right.png" alt="" />
    </div>
  </div>
</template>

<script>
import { getNav } from "@/http/api/home/index";
export default {
  props: {
    isActive: {
      //控制是否选中
      type: String,
      default: "0",
    },
    navData: {
      type: Array,
    },
  },
  data() {
    return {
      activeId: 0,
      navList: [],
    };
  },
  watch: {},
  created() {
    this.getNav();
  },
  methods: {
    getNav() {
      getNav().then((res) => {
        this.navList = res.data.data;
      });
    },
    jump(id, url, type, type2, pid) {
      if (url !== "" && url.indexOf("key") !== -1) {
        //跳转关于我们
        var about = "/about?id=" + id + "&" + url;
        this.$router.push({ path: about });
      } else {
        var news_id = "";
        var nav_id = "";
        //正常跳转
        if (type.length > 0) {
          //父级
          news_id = type[0].id;
          nav_id = id;
        } else {
          //子级
          if (type2 == 1) {
            news_id = id;
            nav_id = id;
          } else {
            news_id = id;
            nav_id = pid;
          }
        }
        var href = "/news?news_id=" + news_id + "&nav_id=" + nav_id;
        this.$router.push({ path: href });
      }
    },
    jumpIndex() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="less" scope>
.nav {
  min-width: 1200px;
  height: 40px;
  position: relative;
  .nav-left {
    width: calc((100% - 1200px) / 2);
    height: 40px;
    position: absolute;
    left: 0px;
    z-index: 9999;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .nav-center {
    width: 1360px;
    height: 56px;
    position: absolute;
    background: url("../assets/images/nav-center.png") no-repeat;
    background-size: 100% 100%;
    left: calc((100% - 1360px) / 2);
    top: -16px;
    .nav-list {
      display: flex;
      margin-left: 170px;
      .item {
        width: 80px;
        height: 56px;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        line-height: 56px;
        color: #ffffff;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
        margin-left: 20px;
        z-index: 9999;
        position: relative;
        .submenu {
          width: 100px;
          display: none;
          height: auto;
          font-size: 12px;
          line-height: 18px;
          color: #666666;
          background: #ffffff;
          font-weight: 700;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .box {
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
          background: #ffffff;
          width: 100px;
        }
      }
      .nav-drop {
        position: absolute;
        bottom: 0px;
        left: 0px;
        display: none;
      }
      .item:hover {
        background: linear-gradient(360deg, #ffffff, #b2d8da 99%);
        color: #005a69;
        cursor: pointer;
      }
      .item:hover .submenu {
        // background: red;
        display: block;
      }
      .item:hover .nav-drop {
        display: block;
      }
      .submenu:hover {
        color: #059e96;
      }
      .active {
        width: 80px;
        height: 56px;
        background: linear-gradient(360deg, #ffffff, #b2d8da 99%);
        color: #005a69;
      }
    }
  }
  .nav-right {
    width: calc((100% - 1200px) / 2);
    height: 40px;
    position: absolute;
    right: 0px;
    z-index: 9999;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
